<template>
  <section class="full-height">
    
    <div class="columns is-centered is-gapless is-marginless">
      <div class="column is-mobile is-narrow mt2rem mb1rem">
        <div class="has-text-grey is-size-2 is-size-4-mobile wa-underlined-title">Roles</div>
        <b-button class="MyButton" @click="Unlock">{{ isUnlocked ? 'Lock' : 'Unlock' }}</b-button>
        <div class="has-text-grey is-size-2 is-size-4-mobile wa-underlined-title">{{ UnlockedTimeMsg }}</div>
      </div>
    </div>
    
    <b-table v-if="isUnlocked && roleData"
    
                :data="roleData"
                :bordered="true"
                :striped="true"
                :narrowed="false"
                :hoverable="true"

                :loading="false"
                :focusable="true"

                :header-checkable="false"
                :mobile-cards="true"
                
                :paginated="isPaginated"
                :per-page="perPage"
                :current-page.sync="currentPage"
                :pagination-simple="isPaginationSimple"
                :pagination-position="paginationPosition"
                
                >
                <!-- checkable -->
                <!-- :checked-rows.sync="tbl1_checkedRows" -->
                
                <template>
                    <b-table-column field="role_id" label="id" width="60" v-slot="props" searchable sortable centered>
                        {{ props.row.role_id }}
                    </b-table-column>
                    
                    <b-table-column field="name" label="name" width="60" v-slot="props" searchable sortable centered>
                        {{ props.row.name }}
                    </b-table-column>
                    
                    <b-table-column field="email" label="email" width="200" v-slot="props" searchable sortable centered>
                        {{ props.row.email }}
                    </b-table-column>
                    
                </template>
                
                <template slot="empty">
                    <section class="section">
                        <div class="content has-text-grey has-text-centered">
                            <p>
                                <b-icon
                                    icon="emoticon-sad"
                                    size="is-large">
                                </b-icon>
                            </p>
                            <p>Geen Roles gevonden.</p>
                        </div>
                    </section>
                </template>
            </b-table>
    
   
    
    <b-notification :closable="false">
      <b-loading :is-full-page="true" :active.sync="pageLoading"></b-loading>
    </b-notification>
  </section>
</template>

<script>

// import { Auth }             from '@/plugins/firebase/auth';
// import { DB }               from '@/plugins/firebase/db';
// import axios_clean          from 'axios';
// import { waitTime }         from '@/helpers/functions';
// import { mapState } from 'vuex';
import { Calc_TimeLeftMsg }         from '@/helpers/functions';
import { get_doubleDigits } from '@/helpers/functions';

export default {
  name: "Streams", 
         
  data() {
    return {
      isUnlocked          : false,
      UnlockedTimeMsg     : '',
      
      pageLoading : false,
      pageLoadingTimeout : null,
      
      roleData: null,
      
      isPaginated: true,
      perPage: 500,
      currentPage: 1,
      isPaginationSimple: false,
      paginationPosition: 'bottom'
    }
  },
  
  async mounted(){
    // this.ShowPageLoading(15000);
    this.UpdateLockStatus();
    // this.GetTableStructureData();
    
    const _this = this;
    setInterval(() => {
      _this.CheckLockStatus();
    }, 1000);
    
    
    // this.roleData = await this.$store.dispatch('databaseactions/DB_GetRoleData');   
    // console.info(`roleData:`, this.roleData);
  },
  
  methods: {
     
     async UpdateLockStatus() {
      // console.info(`-UpdateLockStatus???`);
      const LockStatus = await this.$store.dispatch('databaseactions/DB_GetLockStatus');      
      // console.info(`LockStatus:`, LockStatus);
      this.SetUnlockStatus(LockStatus);
    },
     
    async ShowPageLoading(maxDuration = 30000){
      this.pageLoading = true;
      
      if (this.pageLoadingTimeout !== null) {
        clearTimeout(this.pageLoadingTimeout);
      }
      
      this.pageLoadingTimeout = setTimeout(() => {
        this.HidePageLoading();
      }, maxDuration);
    },
    
    async HidePageLoading() {
      this.pageLoading = false;
      if (this.pageLoadingTimeout !== null) {
        clearTimeout(this.pageLoadingTimeout);
      }
    },
    
    getPrintDateTime(dateStr){
      try {
        let dateobj     = new Date(dateStr);
        const datestr   =  dateobj.getDate() + "-" + (dateobj.getMonth() +1) + "-" + dateobj.getFullYear();
        const timestr   =  get_doubleDigits(dateobj.getHours()) + ":" + get_doubleDigits(dateobj.getMinutes());
        return datestr + " " + timestr;
      } catch (err) {
        console.info("Error getPrintDate:", err.message);
        return "-";
      }
    },
    
    getPrintDate(dateStr){
      try {
        let dateobj     = new Date(dateStr);
        const datestr   =  dateobj.getDate() + "-" + (dateobj.getMonth() +1) + "-" + dateobj.getFullYear();
        return datestr;
      } catch (err) {
        console.info("Error getPrintDate:", err.message);
        return "-";
      }
    },
    getPrintTime(dateStr){
      try {
        let dateobj     = new Date(dateStr);
        const timestr   =  get_doubleDigits(dateobj.getHours()) + ":" + get_doubleDigits(dateobj.getMinutes());
        return timestr;
      } catch (err) {
        console.info("Error getPrintDate:", err.message);
        return "-";
      }
    },
    
    async Unlock() {
      const _this = this;
      
      if (this.isUnlocked) {
        await _this.$store.dispatch('databaseactions/DB_ClearLockStatus', { });
        await _this.UpdateLockStatus();
        return;
      }
      
      this.$buefy.dialog.prompt({
          message: `2FA Code`,
          inputAttrs: {
            placeholder: '2FA Code',
            maxlength: 6
          },
          trapFocus: true,
          onConfirm: async (value) => {
            this.$buefy.toast.open(`2FA Code: ${value}`);
            let rtn = await _this.$store.dispatch('databaseactions/DB_SetLockStatus', { twoFA : value });
            console.info(`rtn:`, rtn);
            
            _this.SetUnlockStatus(rtn);
          }
      })
    },
    
    async SetUnlockStatus(LockStatus){
      if (LockStatus === false || LockStatus === null) {
        this.LockStatus = {
          unlocked: false,
          Locktime: 0,
        }
        this.CheckLockStatus();
        return;
      }
      
      this.LockStatus = LockStatus;
      if (LockStatus.unlocked === true) {
        this.roleData = await this.$store.dispatch('databaseactions/DB_GetRoleData');   
        console.info(`roleData:`, this.roleData);
      }
      this.CheckLockStatus();
    },
    
    
    CheckLockStatus(){
      // console.info(`_this.LockStatus:`, this.LockStatus);
      if (this.LockStatus.unlocked === false) {
        this.isUnlocked      = false;
        this.UnlockedTimeMsg = "not unlocked";
        return;
      } 
      
      let curDate = new Date();
      if (parseInt(this.LockStatus.Locktime) < curDate.getTime()) {
        this.isUnlocked      = false;
        this.UnlockedTimeMsg = "expired";
      } else {
        this.isUnlocked      = true;
        this.UnlockedTimeMsg = Calc_TimeLeftMsg(parseInt(this.LockStatus.Locktime));
      }
    }
        
    
  },
};
</script>

<style scoped>
  .hasError{
    color: red;
  }
  
  .clickable{
    cursor: pointer;
  }
  .clickable:hover{
    color: #AEAEAE;
  }
  
  .itemtext{
    float:left;
    display: inline;
    min-width: 80px;
    font-weight: bold;
    /* background-color: orange;; */
  }
  
  
  .HashStatusOK,
  .GitStatusOK{
    color:darkgreen;
  }
  
  .HashStatusFailed,
  .GitStatusFailed{
    color: red;
  }
  
  .isLink{
    text-decoration: underline;
    margin-left: 5px;
    margin-right: 5px;
  }
  
  .isExpired{
    color: red;
  }
                        
</style>